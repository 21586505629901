import { mainDiv, mainInnerDiv, slider, carouselImage, recipeName, slideContainerDiv, goToRecipe } from './CarouselHome.module.css'
import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Slider from "react-slick";
import { navigate } from 'gatsby';

const CarouselHome = ({whiteBackground}) => {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500
    };

    const navigateToRecipe = recipeUrl => {
        navigate(`/receta/` + recipeUrl);
    }

    const backgroundColor = whiteBackground ? { backgroundColor: 'white' } : {};

    return (
        <div className={mainDiv} style={backgroundColor}>
            <div className={mainInnerDiv}>
                <Slider {...settings} className={slider}>
                <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../images/carousel/budin de zanahoria.jpg" alt="budin de zanahoria" width={1000}/>
                        <h2 className={recipeName}>Budín de Zanahoria</h2>
                        <p className={goToRecipe} onClick={() => navigateToRecipe('como-hacer-budin-de-zanahoria-65646445')}>Ver Video Receta</p>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../images/carousel/bizcochuelo.jpg" alt="postre serenito" width={1000}/>
                        <h2 className={recipeName}>Bizcochuelo Casero</h2>
                        <p className={goToRecipe} onClick={() => navigateToRecipe('como-preparar-bizcochuelo-casero-86664226')}>Ver Video Receta</p>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../images/carousel/postre serenito.jpg" alt="postre serenito" width={1000}/>
                        <h2 className={recipeName}>Postre Serenito Casero</h2>
                        <p className={goToRecipe} onClick={() => navigateToRecipe('como-hacer-postre-serenito-casero-86957846')}>Ver Video Receta</p>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../images/carousel/mousse de maracuya.jpg" alt="mousse de maracuya" width={1000}/>
                        <h2 className={recipeName}>Mousse de Maracuya</h2>
                        <p className={goToRecipe} onClick={() => navigateToRecipe('como-hacer-mousse-de-maracuya-33005987')}>Ver Video Receta</p>
                    </div>
                    <div className={slideContainerDiv}>
                        <StaticImage className={carouselImage} src="../images/carousel/pastafrola.jpg" alt="pastafrola" width={1000}/>
                        <h2 className={recipeName}>Pastafrola</h2>
                        <p className={goToRecipe} onClick={() => navigateToRecipe('pastafrola-41406688')}>Ver Video Receta</p>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default CarouselHome