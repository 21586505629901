import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { youtubeBarDiv, youtubeBarInnerDiv, logoImage, leftDiv, leftP1, leftP2, leftP3, middleDiv, youtubeButton } from './YoutubeBar.module.css'
import useWindowSize from "../../utils/useWindowSize";

const YoutubeBar = () => {

    const { width } = useWindowSize();

    return (
        <div className={youtubeBarDiv}>
            <a href={width > 600 ? 'https://www.youtube.com/FedeCocina' : 'https://m.youtube.com/FedeCocina'} target="_blank" rel="noreferrer" aria-label="YouTube">
                <div className={youtubeBarInnerDiv}>
                    <div className={leftDiv}>
                        <p className={leftP1}>Seguime en Youtube totalmente gratis y no te pierdas ninguna de mis recetas.</p> 
                        <p className={leftP2}>¡Hay una nueva todas las semanas!</p>
                        <p className={leftP3}>Seguime en Youtube y no te pierdas ninguna de mis recetas.</p> 
                    </div>
                    <div className={middleDiv}>
                        <button class={youtubeButton}>Ir a YouTube</button>
                    </div>
                    <StaticImage className={logoImage} src="../../images/youtubeLogo.jpg" imgStyle={{objectFit: 'contain'}} width={85} height={85} alt="Canal Youtube"/>
                </div>
            </a>
        </div>
    )
}

export default YoutubeBar
