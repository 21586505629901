import React from "react"
import { mainDiv, innerDiv, titleSpan, seeMoreButton } from './HomeArticles.module.css'
import ArticleCard from "./ArticleCard"
import { useStaticQuery, graphql } from 'gatsby'
import useWindowSize from "../../utils/useWindowSize";
import { Link } from "gatsby"

const HomeArticles = () => {
    const data  = useStaticQuery(graphql`
    {
        allMysqlAllArticles(sort: {fields: orderVal, order: ASC}, limit: 3) {
        edges {
          node {
            id
            name
            introText
            content
            pageUrl
            publishedAt
          }
        }
      }
    }
    `)

    const { width } = useWindowSize();

    const allArticles = data.allMysqlAllArticles.edges;

      return (
        <div className={mainDiv}>
            <h1 className={titleSpan}>¿A que no sabías?</h1>
            <div className={innerDiv}>
            { allArticles.map((item) => {
                const introText = item.node.introText.replace("{ACTION_BUTTON}", "<b>Seguir Leyendo</>");
                return (
                    <ArticleCard key={item.node.id} pageUrl={item.node.pageUrl} name={item.node.name} introText={introText} rate={item.node.rate} slimMargin={false}></ArticleCard>
                )
            })
            }
            </div>
              <Link className={seeMoreButton} to={'/todos-los-articulos/'}>Ver Más Articulos</Link>
            <></>
        </div>
    )
}

export default HomeArticles
