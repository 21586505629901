import { mainLink, mainDiv, mainSlimDiv, bottomDiv, nameSpan, bottomInnerDiv, pShortText, seeMoreSpan} from './ArticleCard.module.css'
import React from "react"
import { Link } from "gatsby"

const ArticleCard = ({pageUrl, name, introText, rate, slimMargin}) => {
return (
   <Link to={`/articulo/${pageUrl}/`} className={mainLink}>
     <div className={slimMargin ? mainSlimDiv : mainDiv}>
       <div className={bottomDiv}>
         <div className={bottomInnerDiv}>
           <h2 className={nameSpan} dangerouslySetInnerHTML={{__html: name}}></h2>
           <div className={pShortText} dangerouslySetInnerHTML={{__html: introText}}></div>
         </div>
       </div>
     </div>
   </Link>
)
}

export default ArticleCard
