import React, { useState, useEffect } from "react"
import { mainDiv, innerDiv, titleSpan, seeMoreButton } from './HomeDishes.module.css'
import DishCard from "./DishCard"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import useWindowSize from "../../utils/useWindowSize";
import HomeArticles from "../HomeArticles/HomeArticles"

const HomeDishes = () => {
    const data  = useStaticQuery(graphql`
    {
      allMysqlAllRecipes(
        sort: {fields: avgMonthlySearches, order: DESC}
        filter: {type: {eq: "Dulce"}, avgMonthlySearches: {gt: 0}}
        ) {
        edges {
          node {
            id
            image {
              childImageSharp {
                gatsbyImageData(width: 450, placeholder: BLURRED)
              }
            }
            shortName
            introText
            category
            pageUrl
            rate
          }
        }
      }
    }
    `)

    const { width } = useWindowSize();

    var middleCut = width > 500 ? 6 : 3;

    const allRecipes = data.allMysqlAllRecipes.edges;
    const [listTop, setListTop] = useState([...allRecipes.slice(0, middleCut)])
    const [list, setList] = useState([...allRecipes.slice(middleCut, 12)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(allRecipes.length > 9)

    const handleLoadMore = () => {
      setLoadMore(true)
    }

    useEffect(() => {
      if (loadMore && hasMore) {
        const currentLength = list.length
        const isMore = currentLength < allRecipes.length
        const nextResults = isMore
          ? allRecipes.slice(currentLength + middleCut, currentLength + middleCut + 9)
          : []
        setList([...list, ...nextResults])
        setLoadMore(false)
      }
    }, [allRecipes, list, loadMore, hasMore])

    useEffect(() => {
      const isMore = list.length < allRecipes.length
      setHasMore(isMore)
    }, [allRecipes, list])

    return (
        <div className={mainDiv}>
            <h1 className={titleSpan}>Las recetas más buscadas</h1>
            <div className={innerDiv}>
              { listTop.map((item) => {              
                  const image = getImage(item.node.image.childImageSharp);
                  return (
                    <DishCard key={item.node.id} pageUrl={item.node.pageUrl} image={image} name={item.node.shortName} shortText={item.node.introText} category={item.node.category} rate={item.node.rate} slimMargin={false}></DishCard>
                  )
              })
              }
            </div>
            <HomeArticles></HomeArticles>
            <h1 className={titleSpan}>Las mejores recetas</h1>
            <div className={innerDiv}>
              { list.map((item) => {              
                  const image = getImage(item.node.image.childImageSharp);
                  return (
                    <DishCard key={item.node.id} pageUrl={item.node.pageUrl} image={image} name={item.node.shortName} shortText={item.node.introText} category={item.node.category} rate={item.node.rate} slimMargin={false}></DishCard>
                  )
              })
              }
              {hasMore ? (
                <button className={seeMoreButton} onClick={handleLoadMore}>Más Recetas</button>
              ) : 
            <></>}
            </div>
        </div>
    )
}

export default HomeDishes
