import { mainStyles } from './index.module.css'
import React from "react"
import Footer from "../components/Footer"
import NavBar from "../components/NavBar"
import CategoriesBar from "../components/CategoriesBar/CategoriesBar"
import HomeDishes from "../components/HomeDishes/HomeDishes"
import YoutubeBar from "../components/YoutubeBar/YoutubeBar"
import CarouselHome from "../components/CarouselHome"
import { Helmet } from "react-helmet"

const IndexPage = () => {

  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fede Cocina: Las recetas más fáciles y rápidas para que prepares en tu casa</title>
          <meta name="description" content="Las recetas más fáciles y rapidas para que prepares en tu casa."></meta>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
          <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://fedecocina.net/#organization",
                "name": "Fede Cocina",
                "url": "https://fedecocina.net/",
                "sameAs": [
                  "https://www.facebook.com/fede.cocina.ya",
                  "https://www.instagram.com/fede.cocina.ya",
                  "https://www.youtube.com/FedeCocina"
                ],
                "logo": {
                  "@type": "ImageObject",
                  "@id": "https://fedecocina.net/#logo",
                  "inLanguage": "es-AR",
                  "url": "https://fedecocina.net/imagenes/logo.png",
                  "width": 800,
                  "height": 800,
                  "caption": "Fede Cocina"
                },
                "image": {
                  "@id": "https://fedecocina.net/#logo"
                }
              },
              {
                "@type": "WebSite",
                "@id": "https://fedecocina.net/#website",
                "url": "https://fedecocina.net/",
                "name": "Fede Cocina",
                "description": "Las recetas más fáciles y rapidas para que prepares en tu casa.",
                "publisher": {
                  "@id": "https://fedecocina.net/#organization"
                },
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://fedecocina.net/busqueda-receta?search={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "es-AR"
              }
            ]
          }`}
          </script>
        </Helmet>
      <NavBar></NavBar>
      <CarouselHome></CarouselHome>
      <CategoriesBar></CategoriesBar>
      <YoutubeBar></YoutubeBar>
      <HomeDishes></HomeDishes>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
